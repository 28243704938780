export const useGuestProfileStore = defineStore("guest-profile", () => {
  const artistProfile = reactive({
    songs: [],
    social_networks: [],
    profile: null,
  });

  const loaded = ref(false);

  function setArtistProfile(songs, social_networks, profile) {
    artistProfile.songs = songs;
    artistProfile.social_networks = social_networks;
    artistProfile.profile = profile;
    
    loaded.value = true;
  }

  return {
    loaded,
    setArtistProfile,
    artistProfile,
  };
});
